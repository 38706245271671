.actions {
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  align-items: center;
  z-index: 2;

  .favourite {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      transition: fill 0.2s ease;

      &.active {
        fill: var(--red-danger-color);

        & > path {
          transition: all 0.2s ease;

          stroke: var(--red-danger-color);
        }
      }
    }

    @media screen and (min-width: 768px) {
      &:hover {
        .icon {
          fill: var(--red-danger-color);

          & > path {
            stroke: var(--red-danger-color);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    top: -8px;
    right: -8px;
  }

  @media screen and (max-width: 767px) {
    top: -14px;
    right: -14px;
  }
}

.label {
  &Wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      z-index: 2;
      user-select: none;

      @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin-top: 8px;
        margin-left: 8px;
      }
    }

    .bottom {
      margin: auto 4px 4px auto;
      z-index: 2;
      user-select: none;
    }
  }
}
